import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment, ReactElement, useRef, useEffect, useState } from 'react';
import axios from 'axios'; // or you can use fetch

import LinkType from 'types/link';
import { Link as ScrollLink } from 'react-scroll';
// -------- custom hook -------- //
import useSticky from 'hooks/useSticky';
// -------- custom component -------- //
import NextLink from 'components/reuseable/links/NextLink';
import SocialLinks from 'components/reuseable/SocialLinks';
import ListItemLink from 'components/reuseable/links/ListItemLink';
// -------- partial header component -------- //

import * as All from 'data/keywords';
import NavItemsPortfolio from 'components/reuseable/links/NavItemsPortfolio';
import NavItemsServices from 'components/reuseable/links/NavItemsServices';
import NavItemsWithHomeLink from 'components/reuseable/links/NavItemsWithHomeLink';

// ===================================================================
type NavbarProps = {
    isHome?: boolean;
    info?: boolean;
    cart?: boolean;
    fancy?: boolean;
    logoAlt?: string;
    search?: boolean;
    social?: boolean;
    language?: boolean;
    stickyBox?: boolean;
    navClassName?: string;
    button?: ReactElement;
    navOtherClass?: string;
};
// ===================================================================

const Navbar: FC<NavbarProps> = ({ isHome, ...props }) => {
    const router = useRouter();
    const { visitor } = router.query; // Extract the `visitor` query parameter
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (visitor) {
            setMessage(`*** ${visitor} WANTS US TO CONTACT THEM ***`);
        }
    }, [visitor]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const telegramBotToken = process.env.NEXT_PUBLIC_TELEGRAM_BOT_TOKEN;
        const telegramChannelId = process.env.NEXT_PUBLIC_TELEGRAM_CHANNEL_ID;

        if (!telegramBotToken || !telegramChannelId) {
            console.error('Telegram bot token or channel ID is missing');
            return;
        }

        const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

        try {
            const response = await axios.post(url, {
                chat_id: telegramChannelId,
                text: message,
            });

            if (response.status === 200) {
                console.log('Message sent to Telegram successfully');
                // Redirect to the thanks page or show a success message
                router.push('/thanks');
            } else {
                console.error('Failed to send message to Telegram');
            }
        } catch (error) {
            console.error('Error sending message to Telegram:', error);
        }
    };
    const { navClassName, info, search, social, language, button, cart, fancy, navOtherClass, stickyBox, logoAlt } =
        props;

    const sticky = useSticky(350);
    const navbarRef = useRef<HTMLElement | null>(null);

    // dynamically render the logo 
    const logoImage = sticky || navClassName?.includes('navbar-clone') ? '/img/logo/molto-seo-black.png' : '/email-img/molto-white.png';

    // dynamically added navbar classname
    const fixedClassName = 'navbar navbar-expand-lg center-nav transparent navbar-light navbar-clone fixed';

    // render inner nav item links
    const renderLinks = (links: LinkType[]) => {
        return links.map((item) => (
            <ListItemLink href={item.url} title={item.title} linkClassName="dropdown-item" key={item.id} />
        ));
    };

    // all main header contents
    const headerContent = (
        <Fragment>
            <div className="navbar-brand w-100 cursor-pointer" style={{ marginTop: 0 }}>
                <Link
                    href="/"
                    className="d-flex justify-content-center flex-row align-items-center"
                >
                    <div>
                        <img className="logo" alt="Molto SEO" height="50" src={logoImage} />
                    </div>
                </Link>
            </div>

            <div id="offcanvas-nav" data-bs-scroll="true" className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                <div className="offcanvas-header d-lg-none">
                    <h3 className="text-white fs-30 mb-0 text-lowercase">molto</h3>
                    <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" className="btn-close btn-close-white" />
                </div>

                <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                    <ul className="navbar-nav">
                        {isHome && (
                            <>
                                <li className="nav-item dropdown">
                                    <span className="nav-link">
                                        <ScrollLink
                                            activeClass="active"
                                            to="point-process"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={500}
                                        >
                                            <div className="dropdown-item">10 Point Process</div>
                                        </ScrollLink>
                                    </span>
                                </li>
                                <li className="nav-item dropdown">
                                    <span className="nav-link">
                                        <ScrollLink activeClass="active" to="localSEO" spy={true} smooth={true} offset={0} duration={500}>
                                            <div className="dropdown-item">Local SEO</div>
                                        </ScrollLink>
                                    </span>
                                </li>
                                <li className="nav-item dropdown">
                                    <span className="nav-link ">
                                        <ScrollLink activeClass="active" to="ai-seo" spy={true} smooth={true} offset={0} duration={500}>
                                            <div className="dropdown-item">AI SEO</div>
                                        </ScrollLink>
                                    </span>
                                </li>
                            </>
                        )}
                        {!isHome && (
                            <>
                                <NavItemsWithHomeLink />
                            </>
                        )}
                        <li className="nav-item dropdown" title="SEO Audit">
                            <span className="nav-link">
                                <Link href="/london/seo-audit-london">
                                    <div className="dropdown-item">SEO Audit</div>
                                </Link>
                            </span>
                        </li>
                        <NavItemsPortfolio placement="header" active="no" />
                        <li className="nav-item dropdown mobile">
                            <span className="nav-link">
                                {/* <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500}>
                  Contact
                </ScrollLink> */}
                            </span>
                        </li>
                    </ul>

                    <div className="offcanvas-footer d-lg-none">
                        <div>
                            <NextLink title="james@moltoseo.co.uk" className="link-inverse" href="mailto:james@moltoseo.co.uk" />
                            <br />
                            <NextLink href="tel:07441397020" title="+44 (0) 7441 397 020" />
                            <br />
                            <SocialLinks />
                        </div>
                    </div>
                </div>
            </div>

            <div className={navOtherClass}>
                <ul className="navbar-nav flex-row align-items-center ms-auto">
                    {info && (
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info">
                                <i className="uil uil-info-circle" />
                            </a>
                        </li>
                    )}

                    {/* {search && (
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-search">
                <i className="uil uil-search" />
              </a>
            </li> 
          )} */}
                    {/* <li className="nav-item d-none d-md-block"><a className="btn btn-sm btn-primary p-2 py-1" onClick={handleSubmit}>Contact</a></li> */}
                    {/* 
          {cart && (
            <li className="nav-item">
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas-cart"
                className="nav-link position-relative d-flex flex-row align-items-center"
              >
                <i className="uil uil-shopping-cart" />
                <span className="badge badge-cart bg-primary">3</span>
              </a>
            </li>
          )} */}

                    <li className="nav-item d-lg-none">
                        <button
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas-nav"
                            aria-label="menu"
                            className="hamburger offcanvas-nav-btn"
                        >
                            <span />
                        </button>
                    </li>
                </ul>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            {stickyBox && <div style={{ paddingTop: sticky ? navbarRef.current?.clientHeight : 0 }} />}

            <nav ref={navbarRef} className={sticky ? fixedClassName : navClassName}>
                {fancy ? (
                    <div className="container">
                        <div className="navbar-collapse-wrapper bg-white d-flex flex-row flex-nowrap w-100 justify-content-between align-items-center">
                            {headerContent}
                        </div>
                    </div>
                ) : (
                    <div className="container flex-lg-row flex-nowrap align-items-center">{headerContent}</div>
                )}
            </nav>
        </Fragment>
    );
};

// set default Props
Navbar.defaultProps = {
    cart: false,
    info: false,
    social: false,
    search: false,
    language: false,
    stickyBox: true,
    navOtherClass: 'navbar-other w-100 d-flex ms-auto',
    navClassName: 'navbar navbar-expand-lg center-nav transparent navbar-light'
};

export default Navbar;