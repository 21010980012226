import { Fragment, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import ThemeProvider from 'theme/ThemeProvider';

import { Navbar } from 'components/blocks/navbar';
import NextLink from 'components/reuseable/links/NextLink';

// import { UserProvider } from '@auth0/nextjs-auth0/client';
// import { HelmetProvider } from 'react-helmet-async';
// // Bootstrap and custom scss
import 'assets/scss/style.scss';

// // animate css
import 'animate.css';
// import swiper css
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
// // video player css
import 'plyr-react/plyr.css';
// glightbox css
import 'glightbox/dist/css/glightbox.css';
// custom scrollcue css
import 'plugins/scrollcue/scrollCue.css';

import 'assets/fonts/avenir/avenir.css';

function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const router = useRouter();

  // Initially, you can set the state to the direct value from router, but it might be undefined initially
  const [currentPath, setCurrentPath] = useState(router.pathname);

  useEffect(() => {
    setCurrentPath(router.pathname);
    console.log(currentPath)
  }, [router.pathname]);

  const isHome = currentPath === '/';


  useEffect(() => {
    if (typeof window !== 'undefined') {
      // load bootstrap functionality
      (() => {
        const bootstrap = require('bootstrap');

        // Enables multilevel dropdown
        (function (bs) {
          const CLASS_NAME = 'has-child-dropdown-show';

          bs.Dropdown.prototype.toggle = (function (_original) {
            return function () {
              document.querySelectorAll('.' + CLASS_NAME).forEach(function (e) {
                e.classList.remove(CLASS_NAME);
              });
              // @ts-ignore
              let dd = this._element.closest('.dropdown').parentNode.closest('.dropdown');
              for (; dd && dd !== document; dd = dd.parentNode.closest('.dropdown')) {
                dd.classList.add(CLASS_NAME);
              }
              // @ts-ignore
              return _original.call(this);
            };
          })(bs.Dropdown.prototype.toggle);

          document.querySelectorAll('.dropdown').forEach(function (dd) {
            dd.addEventListener('hide.bs.dropdown', function (e) {
              // @ts-ignore
              if (this.classList.contains(CLASS_NAME)) {
                // @ts-ignore
                this.classList.remove(CLASS_NAME);
                e.preventDefault();
              }
              e.stopPropagation();
            });
          });
        })(bootstrap);
      })();
    }
  }, []);

  // scroll animation added
  useEffect(() => {
    (async () => {
      const scrollCue = (await import('plugins/scrollcue')).default;
      scrollCue.init({ interval: -400, duration: 700, percentage: 0.8 });
      scrollCue.update();
    })();
  }, [pathname]);

  return (
    // <HelmetProvider>
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
        <meta
          name="viewport"
          content="target-densitydpi=device-dpi, width=device-width, user-scalable=no, maximum-scale=1, minimum-scale=1"
        />
        <link rel="preconnect" href="https://maps.googleapis.com" />
        {/* Add dns-prefetch hint for Google Maps Utility */}
        <link rel="dns-prefetch" href="https://maps.googleapis.com" />

      </Head>

      <ThemeProvider>
        {/* <div className={styles.content}> */}
        {/* <div className="page-loader" /> */}
        <header className="wrapper bg-soft-primary home" style={{ zIndex: 9 }}>
          <Navbar
            language
            isHome={isHome}
            button={<NextLink title="Contact" href="/#contact" className="btn btn-sm btn-primary" />}
          />
        </header>

        <Component {...pageProps} />
        {/* </div> */}
      </ThemeProvider>
    </Fragment>
    // </HelmetProvider>
  );
}

export default MyApp;
